<template>
    <main id="liste" v-cloak>
        <HeaderTab :title="$t('monte.stock_semence_distant')"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
							<CustomTable
								id_table="stock_distant"
								ref="table"
								:items="stock"
								:busy.sync="table_busy"
								primaryKey="uid"
						        :hrefsRoutes="config_table_hrefs"
								:externSlotColumns="['commentaire']"
							>
								<template v-slot:[`custom-slot-cell(commentaire)`]="{ data }">
									<span v-for="com in data.commentaire" :key="com">
										{{ com }}<br>
									</span>
								</template>
							</CustomTable>
                        </div>
                    </div>
                </div>
            </div>
 		</div>
	</main>
</template>

<script type="text/javascript">
import StockSemence from '@/mixins/StockSemence'

export default {
	name: 'StockDistant',
	mixins: [StockSemence],
	data () {
		return {
			table_busy: false,
	        stock: [],
	        config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'StallionFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				},
				'to.tiers_rs': {
					routeUniqueName: 'tiersTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'to.tiers_id'
					}
				},
			}
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {
			this.table_busy = true
			this.stock = await this.loadAllStockDistant()
			this.table_busy = false
		},
	},

	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
};

</script>